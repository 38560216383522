import React, { useState, useEffect } from "react";
import { auth as firebaseAuth } from "../utils/firebase";

export const AuthContext = React.createContext();

const initialState = {
  isAuth: false,
  username: null,
  isAdmin: false,
  error: null,
};

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(initialState);
  const [loadingAuth, setLoadingAuth] = useState(true);

  useEffect(() => {
    const unsubscribeAuthChanged = firebaseAuth.onAuthStateChanged(user => {
      if (!user) {
        updateAuth({});
      } else {
        updateAuth({
          isAuth: true,
          isAdmin: user.email === "admin@jf.com.br",
          username: user.email,
        });
      }
    });
    return unsubscribeAuthChanged;
  }, []);

  useEffect(() => {
    setLoadingAuth(false);
  }, [auth]);

  const updateAuth = newAuth => {
    setAuth({
      ...initialState,
      ...newAuth,
    });
  };

  const login = async (username, password) => {
    setLoadingAuth(true);
    try {
      await firebaseAuth.signInWithEmailAndPassword(username, password);
    } catch (error) {
      updateAuth({ error: error.message });
    }
  };

  const logout = async () => {
    setLoadingAuth(true);
    await firebaseAuth.signOut();
  };

  return (
    <AuthContext.Provider
      value={{
        auth,
        loadingAuth,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const AuthConsumer = AuthContext.AuthConsumer;
