import React from "react";
import { CardReport, CardReportHeader } from ".";

const CardCadernos = ({ cadernos }) =>
  cadernos.map(({ nome, relatorios }) => (
    <div key={nome} className="card mb-3">
      <div className="card-header">{nome}</div>
      <div className="card-body p-0">
        <table className="table m-0 table-bordered table-hover">
          <CardReportHeader />
          <tbody>
            {relatorios.map(report => (
              <CardReport key={report.reportId} report={report} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  ));

export default CardCadernos;
