import React, { useEffect, useState, useRef } from "react";
import { Alert, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { ReportViewFilterForm, ReportViewPublicacoes } from "./";
import isEqual from "lodash.isequal";
import Constants from "../utils/constants";

const ReportViewTabs = ({ report, aprovacoes }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [filter, setFilter] = useState();
  const [term, setTerm] = useState("");
  const [filtered, setFiltered] = useState(report);

  useEffect(() => {
    const obtemOcorrenciasTermoTextoDescartado = () => {
      if (!term) return null;
      const regex = new RegExp(`${term}`, "ig");
      const ocorrencias = report.texto_descartado.match(regex) || [];
      return ocorrencias.length;
    };

    if (!term) {
      if (!isEqual(filtered, report)) {
        setFiltered(report);
      }
    } else {
      const newFiltered = {
        publicacoes_similares: report.publicacoes_similares.filter(p => {
          const texto = p.diferenca || p.texto;
          return texto.toLowerCase().includes(term.toLowerCase());
        }),
        sij_nao_encontradas: report.sij_nao_encontradas.filter(p => {
          const texto = typeof p === "string" ? p : p.texto || p.textoprocesso;
          return texto.toLowerCase().includes(term.toLowerCase());
        }),
        extrator_nao_encontradas: report.extrator_nao_encontradas.filter(p => {
          const texto = typeof p === "string" ? p : p.texto || p.textoprocesso;
          return texto.toLowerCase().includes(term.toLowerCase());
        }),
        ocorrenciasDescartado: obtemOcorrenciasTermoTextoDescartado(),
      };

      if (!isEqual(newFiltered, filtered)) {
        setFiltered(newFiltered);
      }
    }
  }, [term, filtered, report]);

  const tabs = [
    {
      title: `Publicações Similares (${filtered.publicacoes_similares.length}/${report.publicacoes_similares.length})`,
      render: () => (
        <>
          <ReportViewFilterForm {...{ filter, setFilter }} />
          <ReportViewPublicacoes
            filter={filter}
            reportId={report.reportId}
            aprovacoes={aprovacoes}
            publicacoes={filtered.publicacoes_similares}
            setTerm={setTerm}
          />
        </>
      ),
    },
    {
      title: `Apenas ${Constants.EXTRACAO_1} (${filtered.sij_nao_encontradas.length}/${report.sij_nao_encontradas.length})`,
      render: () => <TabListaPublicacoes publicacoes={filtered.sij_nao_encontradas} setTerm={setTerm} />,
    },
    {
      title: `Apenas ${Constants.EXTRACAO_2} (${filtered.extrator_nao_encontradas.length}/${report.extrator_nao_encontradas.length})`,
      render: () => <TabListaPublicacoes publicacoes={filtered.extrator_nao_encontradas} setTerm={setTerm} />,
    },
    {
      title: `Texto descartado ${filtered.ocorrenciasDescartado ? `(${filtered.ocorrenciasDescartado})` : ""}`,
      render: () => (
        <div className="card p-2 mb-2">
          <BlocoTextoPublicacao publicacao={report.texto_descartado} setTerm={setTerm} />
        </div>
      ),
    },
  ];

  return (
    <>
      <ReportViewSearchTerm term={term} setTerm={setTerm} />
      <Nav tabs>
        {tabs.map((tab, i) => (
          <NavItem key={tab.title}>
            <NavLink className={activeTab === i ? "active" : ""} onClick={() => setActiveTab(i)}>
              {tab.title}
            </NavLink>
          </NavItem>
        ))}
      </Nav>

      <TabContent activeTab={activeTab}>
        {tabs.map((tab, i) => (
          <TabPane key={tab.title} tabId={i} className="p-2">
            {activeTab === i && tab.render()}
          </TabPane>
        ))}
      </TabContent>
    </>
  );
};

const ReportViewSearchTerm = ({ term, setTerm }) => {
  const inputRef = useRef();
  let keyDownDelay;

  useEffect(() => {
    if (inputRef.current.value !== term) {
      inputRef.current.value = term;
    }
  }, [term]);

  const handleKeyDown = evt => {
    clearTimeout(keyDownDelay);
    keyDownDelay = setTimeout(() => setTerm(inputRef.current.value), 500);
  };

  const clearInput = evt => {
    evt.preventDefault();
    inputRef.current.value = "";
    setTerm("");
  };

  return (
    <div className="p-2 bg-light form-inline w-100 mb-2">
      <label className="mr-2">Filtrar publicações por:</label>
      <input placeholder="Termo a ser buscado" ref={inputRef} onChange={handleKeyDown} />
      {term && (
        <button className="btn btn-sm" onClick={clearInput}>
          <i className="fa fa-times" />
        </button>
      )}
    </div>
  );
};

const TabListaPublicacoes = ({ publicacoes, setTerm }) => {
  const handleClickPublicacao = e => {
    if (e.target.tagName === "BUTTON" && e.target.classList.contains("filtrar-cnj")) {
      setTerm(e.target.innerText);
    }
  };

  return (
    <>
      {publicacoes.map((publicacao, idx) => (
        <div className="card p-2 mb-2" key={idx} onClick={handleClickPublicacao}>
          <MetadadosPublicacao publicacao={publicacao} />
          <BlocoTextoPublicacao publicacao={publicacao} setTerm={setTerm} />
        </div>
      ))}
      {!publicacoes.length && <Alert color="info">Nenhuma publicação encontrada!</Alert>}
    </>
  );
};

const MetadadosPublicacao = ({ publicacao }) => {
  let metadados = {
    Vara: publicacao.NomeVara || publicacao.orgao_julgador,
    Origem: publicacao.origem,
    Expediente: publicacao.expediente,
  };
  return (
    <div className="metadados">
      <ul className="list-unstyled mb-2">
        {Object.keys(metadados).map((metadado_key, i) => (
          <li key={metadado_key} className={`${metadados[metadado_key] ? "" : "d-none"}`}>
            <strong>{metadado_key}</strong>: {metadados[metadado_key]}
          </li>
        ))}
      </ul>
    </div>
  );
};

const BlocoTextoPublicacao = ({ publicacao }) => {
  const texto = trataRawTexto(
    typeof publicacao === "string" ? publicacao : publicacao.texto || publicacao.textoprocesso,
  );

  return <div dangerouslySetInnerHTML={{ __html: texto }} />;
};

const trataRawTexto = texto => {
  return texto.replace(/(\r\n)+|\n+/gm, "<br/>");
};

export default ReportViewTabs;
