import React from "react";
import PropTypes from "prop-types";
import { Diff2Html } from "diff2html";

const DiffView = ({ diffText }) => {
  const diffHtml = Diff2Html.getPrettyHtml(diffText, {
    inputFormat: "diff",
    outputFormat: "side-by-side",
  });

  return <div className="publicacoes__list-item__diff" dangerouslySetInnerHTML={{ __html: diffHtml }} />;
};

DiffView.propTypes = {
  diffText: PropTypes.string.isRequired,
};

export default DiffView;
