import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../contexts";
import { Link } from "@reach/router";
import { Collapse } from "reactstrap";
import { Loader, CardCadernos } from "../components";
import { useReports } from "../hooks";

const ReportListPage = ({ tribunalId }) => {
  const {
    auth: { isAdmin },
  } = useContext(AuthContext);
  const { groupedReports, reportsLoaded } = useReports({ tribunalId, isAdmin });
  const [openedTribunal, setOpenedTribunal] = useState(null);

  useEffect(() => {
    if (!groupedReports || openedTribunal !== null) return;
    setOpenedTribunal(groupedReports && groupedReports[0] ? groupedReports[0].nome : null);
  }, [groupedReports, openedTribunal]);

  const toggleCollapseTribunal = nome => {
    setOpenedTribunal(openedTribunal === nome ? "" : nome);
  };

  const isOpen = nome => openedTribunal === nome;

  const cleanNomeTribunal = nome => nome.replace("tribunais.", "").replace(/\./g, " / ");

  return (
    <>
      <Loader active={!reportsLoaded} />
      <h2 className="border-bottom pb-2 mb-3">Relatórios</h2>

      {reportsLoaded === false && (
        <div className="alert alert-info">
          <i className="fa fa-spin fa-spinner fa-lg" />
          Carregando dados de relatórios
        </div>
      )}

      {groupedReports &&
        groupedReports.map(({ nome, cadernos }) => (
          <div key={nome} className="card border-0">
            <div className="card-header card-header-tribunal">
              <div className="pull-right">
                {nome !== tribunalId && (
                  <>
                    <small className="pr-3">
                      <strong>{cadernos.length}</strong> Cadernos diferentes adicionados
                    </small>
                    <Link to={`tribunal/${nome}/`}>
                      <i className="fa fa-search" /> Ver tribunal
                    </Link>
                  </>
                )}
              </div>
              <p className="m-0 font-weight-bold text-uppercase" onClick={() => toggleCollapseTribunal(nome)}>
                <i className={`fa fa-caret-${isOpen(nome) ? "right" : "down"}`} /> {cleanNomeTribunal(nome)}
              </p>
            </div>

            <Collapse isOpen={isOpen(nome)} className="mt-3 px-3">
              {isOpen(nome) && <CardCadernos cadernos={cadernos} />}
            </Collapse>
          </div>
        ))}
    </>
  );
};

export default ReportListPage;
