import { useState, useEffect } from "react";
import { inflate } from "pako";
import { storage, db } from "../utils/firebase";
import isEqual from "lodash.isequal";
import extratorCnj from "../utils/ExtratorCnj";

const useReport = reportId => {
  const [reportFb, setReportFb] = useState(null);
  const [reportJson, setReportJson] = useState(null);
  const [report, setReport] = useState(null);
  const [aprovacoes, setAprovacoes] = useState(null);
  const [estatisticas, setEstatisticas] = useState(null);

  useEffect(() => {
    const reportRef = db.ref("indice").child(reportId);
    reportRef.on("value", snap => {
      setReportFb(snap.val() || {});
    });

    return () => reportRef.off();
  }, [reportId]);

  const fetchJsonReport = async reportId => {
    const url = await storage.ref(`${reportId}.gz`).getDownloadURL();
    const result = await fetch(url);
    const buffer = await result.arrayBuffer();
    const data = inflate(buffer, { to: "string" });
    return extratorCnj(JSON.parse(data));
  };

  useEffect(() => {
    const getReport = async reportId => {
      setReportJson(await fetchJsonReport(reportId));
    };
    getReport(reportId);
  }, [reportId]);

  useEffect(() => {
    const refAprovacoes = db.ref(`aprovacoes/${reportId}`);
    refAprovacoes.on("value", snap => {
      setAprovacoes(snap.val() || {});
    });

    return () => refAprovacoes.off();
  }, [reportId]);

  useEffect(() => {
    if (reportJson && Object.keys(reportJson).length && reportFb && aprovacoes) {
      setEstatisticas(getReportStats(reportJson, aprovacoes));
    }
  }, [reportJson, reportFb, aprovacoes]);

  useEffect(() => {
    if (estatisticas) {
      const newReport = {
        ...reportJson,
        ...reportFb,
        ...estatisticas,
        reportId,
      };
      if (!isEqual(newReport, report)) {
        setReport({
          ...newReport,
        });
      }
    }
  }, [estatisticas, report, reportFb, reportId, reportJson]);

  useEffect(() => {
    const reportRef = db.ref(`indice/${reportId}`);
    const newReportFb = {
      ...reportFb,
      ...estatisticas,
    };
    if (report && !isEqual(reportFb, newReportFb)) {
      reportRef.update({ ...newReportFb });
    }

    return () => reportRef.off();
  }, [estatisticas, report, reportFb, reportId]);

  return { report, aprovacoes };
};

const getReportStats = (reportJson, aprovacoes) => {
  const stats = {
    totalItens: reportJson.publicacoes_similares.length,
    itensIguais: 0,
    itensAprovados: 0,
    itensReprovados: 0,
    itensCriticos: 0,
    qtd_sij_com_vara: 0,
    qtd_extrator_com_vara: 0,
    cnj_similares_sij: 0,
    cnj_similares_extrator: 0,
    cnj_apenas_sij: 0,
    cnj_apenas_extrator: 0,
  };

  reportJson.publicacoes_similares.forEach(item => {
    const key = `${item.codigo}__${item.hash}`;
    if (item.taxa_similaridade === 1) {
      stats.itensIguais++;
    } else if (aprovacoes[key] === 0) {
      stats.itensAprovados++;
    } else if (aprovacoes[key] === 1) {
      stats.itensReprovados++;
    } else if (aprovacoes[key] === 2) {
      stats.itensCriticos++;
    }

    if (item.metadados_sij && item.metadados_sij.NomeVara) {
      stats.qtd_sij_com_vara++;
    }

    if (item.metadados_extrator && item.metadados_extrator.orgao_julgador) {
      stats.qtd_extrator_com_vara++;
    }
    stats.cnj_similares_sij += item.cnjs.sij.length;
    stats.cnj_similares_extrator += item.cnjs.extrator.length;
  });

  reportJson.sij_nao_encontradas.forEach(item => {
    if (item.NomeVara) {
      stats.qtd_sij_com_vara++;
    }
    stats.cnj_apenas_sij += item.cnjs.length;
  });

  reportJson.extrator_nao_encontradas.forEach(item => {
    if (item.orgao_julgador) {
      stats.qtd_extrator_com_vara++;
    }
    stats.cnj_apenas_extrator += item.cnjs.length;
  });

  return stats;
};
export default useReport;
