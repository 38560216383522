import { db, storage } from "../utils/firebase";

const useReportFunctions = () => {
  const deleteReport = async ({ reportId, ref_relatorio }) => {
    const confirmDelete = window.confirm("Deseja realmente excluir este relatório?");
    if (confirmDelete) {
      const refReport = db.ref(`indice/${reportId}`);
      await refReport.remove();
      await storage.ref(ref_relatorio).delete();
    }
  };

  const changeRelease = async ({ reportId, released }) => {
    const confirmToggleVsibility = window.confirm(
      `Deseja tornar este relatório ${released ? "oculto" : "visivel"} para o revisor?`,
    );
    if (confirmToggleVsibility) {
      const refReport = db.ref(`indice/${reportId}`);
      await refReport.update({ released: !released });
    }
  };

  const removeValidations = async ({ reportId }) => {
    const confirmRemoveValidations = window.confirm("Deseja realmente excluir todas as validações deste relatório?");
    if (confirmRemoveValidations) {
      const refAprovacoes = db.ref(`aprovacoes/${reportId}`);
      await refAprovacoes.remove();
      refAprovacoes.off();

      const refReport = db.ref(`indice/${reportId}`);
      await refReport.update({
        itensAprovados: 0,
        itensReprovados: 0,
      });
    }
  };

  return { deleteReport, changeRelease, removeValidations };
};

export default useReportFunctions;
