import React from "react";

const ReportViewSimilarity = ({ avg }) => {
  const perc = (avg * 100).toFixed(2);

  return (
    <div>
      <strong>Nível de similaridade geral: </strong>
      <span className="pl-2">{perc}%</span>
      <small className="pl-2">Este calculo considera apenas as publicações vinculadas</small>
      <div className="publicacoes__nivel-similaridade">
        <div style={{ width: `${perc}%` }} />
      </div>
    </div>
  );
};

export default ReportViewSimilarity;
