import React from "react";
import ReactDOM from "react-dom";
import { AuthProvider } from "./contexts";
import App from "./app";

import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import "diff2html/dist/diff2html.css";
import "./styles.scss";

const rootElement = document.getElementById("root");

ReactDOM.render(
  <AuthProvider>
    <App />
  </AuthProvider>,
  rootElement,
);
