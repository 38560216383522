import React, { useEffect, useContext } from "react";
import isEqual from "lodash.isequal";
import { AuthContext } from "../contexts";

const ReportViewFilterForm = ({ filter, setFilter }) => {
  const {
    auth: { isAdmin },
  } = useContext(AuthContext);

  useEffect(() => {
    if (!filter) {
      updateFilter({
        exibirAprovados: false,
        exibirReprovados: false,
        exibirIguais: false,
        exibirCriticos: false,
        exibirMetadados: true,
        exibirCnjs: true,
        similaridade: isAdmin ? "100" : "80",
      });
    }
  });

  const updateFilter = newFilter => {
    if (!isEqual(filter, newFilter)) {
      setFilter(newFilter);
    }
  };

  const handleInputChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    updateFilter({
      ...filter,
      [name]: value,
    });
  };

  const niveisSimilaridade = [
    { text: "Todos", value: 100 },
    { text: "Menor que 90%", value: 90 },
    { text: "Menor que 80%", value: 80 },
    { text: "Menor que 70%", value: 70 },
    { text: "Menor que 60%", value: 60 },
    { text: "Menor que 50%", value: 50 },
  ];

  const itensVisibilidade = [
    { text: "Aprovados", value: "exibirAprovados" },
    { text: "Reprovados", value: "exibirReprovados" },
    { text: "Criticos", value: "exibirCriticos" },
    { text: "Iguais", value: "exibirIguais" },
    { text: "Exibir Metadados", value: "exibirMetadados" },
  ];

  if (!filter) return null;

  return (
    <div className="form-inline">
      <label>Filtrar similaridade</label>
      <select className="ml-2" name="similaridade" value={filter.similaridade} onChange={e => handleInputChange(e)}>
        {niveisSimilaridade.map((item, idx) => (
          <option key={idx} value={item.value}>
            {item.text}
          </option>
        ))}
      </select>

      <label className="ml-4 mr-2">Exibir:</label>
      <div className="btn-group">
        {itensVisibilidade.map(item => (
          <label
            key={item.value}
            style={{ cursor: "pointer" }}
            className={`btn btn-sm btn-outline-${filter[item.value] ? "primary active" : "secondary"}`}
          >
            <input
              className="d-none"
              type="checkbox"
              id={item.value}
              name={item.value}
              checked={filter[item.value]}
              onChange={e => handleInputChange(e)}
            />
            {item.text}
          </label>
        ))}
      </div>
    </div>
  );
};

export default ReportViewFilterForm;
