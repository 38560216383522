import React from "react";
import { Navbar } from "./components";
import { Router } from "@reach/router";
import { LoginPage } from "./pages";
import { PrivateRoute, ReportListPage, ReportViewPage } from "./pages";

const App = () => {
  return (
    <>
      <div className="pb-5">
        <Navbar />
      </div>
      <div className="px-4 mt-5">
        <Router>
          <LoginPage path="/" />
          <PrivateRoute path="/app">
            <ReportListPage path="/tribunal/:tribunalId/" />
            <ReportListPage path="/" />
            <ReportViewPage path="/report/:reportId" />
          </PrivateRoute>
        </Router>
      </div>
    </>
  );
};

export default App;
