import React from "react";
import Constants from "../utils/constants";

const ReportViewStats = props => (
  <div className="publicacoes__estatisticas">
    <p className="publicacoes__estatisticas--sij">
      <span>{Constants.EXTRACAO_1}:</span>
      <strong>{props.qtd_publicacoes_sij}</strong>
    </p>

    <p className="publicacoes__estatisticas--extrator">
      <span>{Constants.EXTRACAO_2}:</span>
      <strong>{props.qtd_publicacoes_extrator}</strong>
    </p>

    <p className="publicacoes__estatisticas--vinculos">
      <span>Apenas na {Constants.EXTRACAO_1}:</span>
      <strong>{props.sij_nao_encontradas.length}</strong>
    </p>

    <p className="publicacoes__estatisticas--vinculos">
      <span>Apenas no {Constants.EXTRACAO_2}:</span>
      <strong>{props.extrator_nao_encontradas.length}</strong>
    </p>

    <p className="publicacoes__estatisticas--vinculos">
      <span>Vinculadas:</span>
      <strong>{props.publicacoes_similares.length}</strong>
    </p>

    <p>
      <span>{Constants.EXTRACAO_1} com Vara:</span>
      <strong>{props.qtd_sij_com_vara}</strong>
    </p>

    <p>
      <span>{Constants.EXTRACAO_2} com Vara:</span>
      <strong>{props.qtd_extrator_com_vara}</strong>
    </p>

    <p>
      <span>Iguais:</span>
      <strong className="text-success">{props.itensIguais}</strong>
    </p>

    <p>
      <span>Aprovadas:</span>
      <strong className="text-success">
        {props.itensAprovados}/<small>{props.publicacoes_similares.length - props.itensIguais || 0}</small>
      </strong>
    </p>

    <p>
      <span>Reprovadas:</span>
      <strong className="text-warning">
        {props.itensReprovados}/<small>{props.publicacoes_similares.length - props.itensIguais || 0}</small>
      </strong>
    </p>

    <p>
      <span>Criticos:</span>
      <strong className="text-danger">
        {props.itensCriticos}/<small>{props.publicacoes_similares.length - props.itensIguais || 0}</small>
      </strong>
    </p>
  </div>
);

export default ReportViewStats;
