import React, { useState } from "react";
import { DiffView, ReportItemComentarios } from "./";
import Constants from "../utils/constants";

const ReportItem = ({
  publicacao: { taxa_similaridade, diferenca, texto, codigo, hash, metadados_sij, metadados_extrator, cnjs },
  status,
  onChangeStatus,
  reportId,
  filter,
  setTerm,
}) => {
  const statusKey = `${codigo}__${hash}`;
  const [showContent, setShowContent] = useState(true);

  const handleChangeStatus = (e, statusKey, value) => {
    e.stopPropagation();
    onChangeStatus(statusKey, value);
  };

  const validations = [
    { text: "Limpar", value: undefined, color: "" },
    { text: "Reprovado Crítico", value: 2, color: "danger" },
    { text: "Reprovado", value: 1, color: "warning" },
    { text: "Aprovado", value: 0, color: "success" },
  ];

  const renderContent = () => {
    if (diferenca) {
      var regex = /--- sij$\n\+\+\+ extrator/m
      var replace = `--- ${Constants.EXTRACAO_1}\n+++ ${Constants.EXTRACAO_2}`
      diferenca = diferenca.replace(regex, replace)
      return <DiffView diffText={diferenca} />;
    }

    return (
      <div
        className="publicacoes__list-item__diff"
        dangerouslySetInnerHTML={{ __html: texto.split("\n").join("<br />") }}
      />
    );
  };

  return (
    <div className="publicacoes__list-item w-100">
      <div className="publicacoes__list-item__header" onClick={() => setShowContent(!showContent)}>
        <i className={`toggle-content fa fa-caret-${showContent ? "down" : "up"}`} />

        <span className="ml-2 float-right">
          {validations.map(item => {
            if (item.value === undefined && status === undefined) return null;
            return (
              <button
                key={item.text}
                className={`btn btn-outline-${item.color} btn-sm mr-3 py-0 ${status === item.value ? "active" : ""}`}
                onClick={e => handleChangeStatus(e, statusKey, item.value)}
              >
                {item.text}
              </button>
            );
          })}
        </span>

        <span className="pr-1">Publicação: ({codigo})</span>
        <small>
          Taxa de Similaridade: <strong>{(taxa_similaridade * 100).toFixed(2)}%</strong>
        </small>
      </div>

      {showContent && (
        <div className="row">
          <div className={status > 0 ? "col-8" : "col"}>
            {filter.exibirMetadados && (
              <MetadadosPublicacao
                metadadosSij={metadados_sij}
                metadadosExtrator={metadados_extrator}
                cnjs={cnjs}
                setTerm={setTerm}
              />
            )}
            {renderContent()}
          </div>

          {status > 0 && (
            <div className="col-4 bg-light p-3">
              <ReportItemComentarios codigo={codigo} hash={hash} reportId={reportId} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const MetadadosPublicacao = ({ metadadosSij, metadadosExtrator, cnjs, setTerm }) => {
  if (!metadadosSij || !metadadosExtrator) {
    return null;
  }

  const metadados = {
    sij: {
      Vara: metadadosSij.NomeVara || "-/-",
    },
    extrator: {
      Vara: metadadosExtrator.orgao_julgador || "-/-",
      Origem: metadadosExtrator.origem,
      Expediente: metadadosExtrator.expediente,
    },
  };

  const handleCNJCLick = e => {
    setTerm(e.target.innerText);
  };

  return (
    <div className="row metadados">
      {Object.entries(metadados).map(([key, value]) => (
        <div className="col" key={key}>
          <ul className="m-0">
            {Object.keys(value).map((metadado_key, i) => {
              if (!metadados[key][metadado_key]) return null;
              return (
                <li key={metadado_key}>
                  <strong>{metadado_key}</strong>: {metadados[key][metadado_key]}
                </li>
              );
            })}
            {cnjs && cnjs[key].length > 0 && (
              <li>
                <strong>CNJs Encontrados:</strong>
                {Array.from(new Set(cnjs[key])).map((cnj, idx) => (
                  <button className="m-1 btn btn-primary btn-sm py-0" key={idx} onClick={handleCNJCLick}>
                    {cnj}
                  </button>
                ))}
              </li>
            )}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default ReportItem;
