import React from "react";

const ReportViewPaginador = ({
  setPagination,
  pagination,
  pagination: { indexAtual, itemsPerPage, paginaAtual },
  itensCount,
}) => {
  const handlePageChange = step => {
    setPagination({
      ...pagination,
      paginaAtual: paginaAtual + step,
      indexAtual: (paginaAtual + step) * itemsPerPage,
    });
  };

  const handleNextPage = () => handlePageChange(1);

  const handlePreviousPage = () => handlePageChange(-1);

  const handlePageCountChange = event => {
    setPagination({
      ...pagination,
      itemsPerPage: Number(event.target.value),
      indexAtual: 0,
      paginaAtual: 0,
    });
  };

  const indexFinal = indexAtual + itemsPerPage;
  const itemsPerPageList = [5, 10, 25, 50, 100, 250];

  if (!indexFinal) {
    return null;
  }

  return (
    <div className="col text-right">
      <div className="mr-2 d-inline-block">
        <span>Itens por página</span>
        <select className="ml-2" name="itemsPerPage" onChange={evt => handlePageCountChange(evt)} value={itemsPerPage}>
          {itemsPerPageList.map((value, idx) => (
            <option key={idx} value={value}>
              {value}
            </option>
          ))}
        </select>
      </div>
      <span>
        Exibindo de {indexAtual} até {indexFinal < itensCount ? indexFinal : itensCount} de {itensCount}
      </span>
      <span className="ml-3 d-inline-block">
        <button disabled={indexAtual === 0} onClick={handlePreviousPage} className="mx-1 btn btn-sm btn-primary">
          <i className="fa fa-arrow-left" />
        </button>
        <span className="mx-1">{paginaAtual + 1}</span>
        <button disabled={indexFinal >= itensCount} onClick={handleNextPage} className="mx-1 btn btn-sm btn-primary">
          <i className="fa fa-arrow-right" />
        </button>
      </span>
    </div>
  );
};

export default ReportViewPaginador;
