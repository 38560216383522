import React, { useContext } from "react";
import { AuthContext } from "../contexts/AuthContext";
import { Redirect } from "@reach/router";
import Loader from "../components/Loader";

const PrivateRoute = props => {
  const {
    auth: { isAuth },
    loadingAuth,
  } = useContext(AuthContext);

  if (loadingAuth === true) {
    return <Loader active={loadingAuth} />;
  }

  if (isAuth === true) {
    return <>{props.children}</>;
  }

  return <Redirect to="/" state={{ referrer: props.location.pathname }} noThrow />;
};

export default PrivateRoute;
