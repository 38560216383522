import React from "react";
import { ReportViewHeader, ReportViewStats, ReportViewSimilarity, ReportViewTabs } from "./";

const ReportView = React.memo(({ report, aprovacoes }) => {
  return (
    <>
      <ReportViewHeader {...report} />
      <ReportViewStats {...report} />
      <ReportViewSimilarity avg={report.media_de_similaridade} />
      <ReportViewTabs report={report} aprovacoes={aprovacoes} />
    </>
  );
});

export default ReportView;
