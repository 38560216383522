import React, { useRef, useContext, useEffect } from "react";
import { Alert, Button, Form, FormGroup, Input, Label } from "reactstrap";
import Loader from "../components/Loader";
import { AuthContext } from "../contexts";
import { navigate } from "@reach/router";

const LoginPage = props => {
  const formRef = useRef();
  const {
    auth: { isAuth, loading, error },
    login,
  } = useContext(AuthContext);

  useEffect(() => {
    const {
      location: { state = {} },
    } = props;
    const redirectTo = state && state.referrer ? state.referrer : "/app";
    if (isAuth) {
      navigate(redirectTo);
    }
  }, [props, isAuth]);

  const handleSubmit = async evt => {
    evt.preventDefault();
    const { username, password } = formRef.current;
    await login(username.value, password.value);
  };

  return (
    <>
      <div className="row">
        <div className="col-2 offset-5 border py-2">
          <Alert color="danger" isOpen={error != null}>
            {error}
          </Alert>

          <Form onSubmit={handleSubmit} innerRef={formRef}>
            <FormGroup>
              <Label for="username">Usuário</Label>
              <Input type="email" id="username" placeholder="Email" required autoComplete="off" />
            </FormGroup>
            <FormGroup>
              <Label for="password">Senha</Label>
              <Input type="password" id="password" placeholder="Senha" required autoComplete="off" />
            </FormGroup>
            <Button color="primary float-right">Login</Button>
          </Form>
        </div>
      </div>

      <Loader active={loading} />
    </>
  );
};

export default LoginPage;
