import React, { useState, useEffect, useRef, useContext } from "react";
import { db } from "../utils/firebase";
import { AuthContext } from "../contexts";
import { Alert } from "reactstrap";
import isEqual from "lodash.isequal";

const ReportItemComentarios = ({ codigo, hash }) => {
  const refTextarea = useRef();
  const refComentarios = db.ref(`comentarios/${codigo}`);

  const { auth } = useContext(AuthContext);
  const [comentarios, setComentarios] = useState(null);

  useEffect(() => {
    refComentarios.on("value", snap => {
      if (isEqual(comentarios, snap.val())) return;
      setComentarios(snap.val());
    });

    return () => {
      refComentarios.off();
    };
  }, [refComentarios, comentarios]);

  const handleTextareaKeyDown = evt => {
    if (evt.keyCode === 13 && (evt.ctrlKey || evt.metaKey)) {
      handleSubmitComentario(evt);
    }
  };

  const handleSubmitComentario = evt => {
    evt.preventDefault();
    const texto = refTextarea.current.value;
    if (!texto) return null;

    refTextarea.current.value = "";

    refComentarios.push({
      username: auth.username,
      timestamp: new Date().getTime(),
      texto,
      hash,
    });
  };

  const deleteComment = (e, key) => {
    e.preventDefault();
    refComentarios.child(key).remove();
  };

  return (
    <>
      <h5 className="mb-3">Comentários</h5>
      {!comentarios && <Alert color="info">Nenhum comentário adicionado.</Alert>}
      {comentarios &&
        Object.entries(comentarios).map(([key, { timestamp, username, texto, hash: hash_ }]) => (
          <div className="p-1 border-bottom my-1" key={timestamp}>
            <p className="mb-0">
              <strong>{username}</strong>
              <small>- {new Date(timestamp).toLocaleString("en-GB")}</small>
              {hash_ !== hash && (
                <small>
                  <em>Comentário referente a uma versão anterior</em>
                </small>
              )}
              <button onClick={e => deleteComment(e, key)} className="btn btn-sm text-danger">
                <i className="fa fa-times" />
              </button>
            </p>
            <p>{texto}</p>
          </div>
        ))}

      <form onSubmit={handleSubmitComentario}>
        <textarea className="form-control" rows="2" ref={refTextarea} onKeyDown={handleTextareaKeyDown} />
        <p className="text-right mb-0">
          <small>
            (Envie com <kbd>CTL+ENTER</kbd> ou <kbd>CMD+ENTER</kbd>)
          </small>
          <button type="submit" className="btn btn-sm btn-primary ml-3 mt-2 px-5 py-1">
            Enviar
          </button>
        </p>
      </form>
    </>
  );
};

export default ReportItemComentarios;
