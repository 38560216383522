import React from "react";
import { Loader, ReportView } from "../components";
import { useReport } from "../hooks";

const ReportViewPage = ({ reportId }) => {
  const { report, aprovacoes } = useReport(reportId.replace(".gz", ""));
  return (
    <>
      <Loader active={!report} message="Carregando..." />
      {report && <ReportView report={report} aprovacoes={aprovacoes} />}
    </>
  );
};

export default ReportViewPage;
