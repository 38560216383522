import React, { useContext } from "react";
import { Link } from "@reach/router";
import { Progress } from "reactstrap";
import { AuthContext } from "../contexts";
import { useReportFunctions } from "../hooks";

const CardReport = ({ report }) => {
  if (!report.ref_relatorio) {
    return null;
  }

  return (
    <>
      <tr>
        <td>{report.data_publicacao}</td>
        <QuantidadePublicacoes {...report} />
        <QuantidadePublicacoesNaoVinculadas {...report} />
        {(isNaN(report.itensIguais) && isNaN(report.totalItens)) === false && (
          <>
            <QuantidadePublicacoesSemVara {...report} />
            <QuantidadeValidacoesPublicacoes {...report} />
          </>
        )}
        {(isNaN(report.itensIguais) || isNaN(report.totalItens)) === true && (
          <td className="p-0 pt-3" colSpan="7" valign="middle">
            <div className="text-center text-warning">
              <i className="fa fa-exclamation-triangle" /> Estatísticas não processadas, abra o relatório para
              processá-las
            </div>
          </td>
        )}

        <ProgressoSimilaridade {...report} />
        <CardReportAcoes {...report} />
      </tr>
      {report.cnj_similares_sij !== undefined && (
        <tr>
          <td>CNJS</td>
          <td className="text-right">{report.cnj_similares_sij}</td>
          <td className="text-right">{report.cnj_similares_extrator}</td>
          <td className="text-right" />
          <td className="text-right">{report.cnj_apenas_sij}</td>
          <td className="text-right">{report.cnj_apenas_extrator}</td>
          <td className="text-right" />
          <td className="text-right" />
          <td colSpan="4">
            CNJ em Texto descartado: {report.cnjs_texto_descartado ? report.cnjs_texto_descartado.length : 0}
          </td>
          <td />
          <td />
          <td />
        </tr>
      )}
      {(isNaN(report.itensIguais) && isNaN(report.totalItens)) === false && (
        <ProgressoValidacaoPublicacoes {...report} />
      )}
    </>
  );
};

const PorcentagemItem = ({ valor, total }) => {
  const obtemPorcentagem = (item, total) => {
    if (item > 0) {
      return ((item / total) * 100).toFixed(2);
    } else if (item === 0) {
      return 0;
    } else {
      return "-/-";
    }
  };

  return (
    <>
      {" "}
      {isNaN(valor) === false && (
        <small>
          <em>({obtemPorcentagem(valor, total)}%)</em>
        </small>
      )}
    </>
  );
};

const QuantidadePublicacoes = ({ qtd_publicacoes_sij, qtd_publicacoes_extrator }) => {
  const saldoPublicacoes = qtd_publicacoes_sij - qtd_publicacoes_extrator;

  const getDiffClassname = saldo => {
    if (saldo > 0) {
      return "table-success text-success";
    } else if (saldo < 0) {
      return "table-warning text-warning";
    } else {
      return "";
    }
  };

  return (
    <>
      <td>
        <strong>{qtd_publicacoes_sij}</strong>
      </td>
      <td>
        <strong>{qtd_publicacoes_extrator}</strong>
      </td>
      <td className={getDiffClassname(saldoPublicacoes)}>
        <strong>{saldoPublicacoes}</strong>
      </td>
    </>
  );
};

const QuantidadePublicacoesNaoVinculadas = ({
  qtd_publicacoes_sij,
  qtd_publicacoes_extrator,
  qtd_sij_nao_encontradas,
  qtd_extrator_nao_encontradas,
}) => {
  return (
    <>
      <td>
        <strong>
          {qtd_sij_nao_encontradas ? qtd_sij_nao_encontradas : 0}
          <PorcentagemItem valor={qtd_sij_nao_encontradas} total={qtd_publicacoes_sij} />
        </strong>
      </td>
      <td>
        <strong>
          {qtd_extrator_nao_encontradas ? qtd_extrator_nao_encontradas : 0}
          <PorcentagemItem valor={qtd_extrator_nao_encontradas} total={qtd_publicacoes_extrator} />
        </strong>
      </td>
    </>
  );
};

const QuantidadePublicacoesSemVara = ({
  totalItens,
  qtd_publicacoes_sij,
  qtd_publicacoes_extrator,
  qtd_sij_com_vara,
  qtd_extrator_com_vara,
}) => {
  const sij_sem_varas = qtd_sij_com_vara ? qtd_publicacoes_sij - qtd_sij_com_vara : "-/-";
  const extrator_sem_varas = qtd_extrator_com_vara ? qtd_publicacoes_extrator - qtd_extrator_com_vara : "-/-";
  return (
    <>
      <td className={sij_sem_varas > 0 ? "table-danger" : ""}>
        <strong>
          {sij_sem_varas}
          <PorcentagemItem valor={sij_sem_varas} total={totalItens} />
        </strong>
      </td>
      <td className={extrator_sem_varas > 0 ? "table-danger" : ""}>
        <strong>
          {extrator_sem_varas}
          <PorcentagemItem valor={extrator_sem_varas} total={totalItens} />
        </strong>
      </td>
    </>
  );
};

const QuantidadeValidacoesPublicacoes = ({
  itensIguais,
  totalItens,
  itensAprovados,
  itensReprovados,
  itensCriticos,
}) => {
  const paraAprovar = totalItens - itensIguais || 0;
  const pendentes = paraAprovar - itensAprovados - itensReprovados - itensCriticos;

  return (
    <>
      <td>
        <strong>
          {isNaN(itensIguais) ? "-/-" : itensIguais}
          <PorcentagemItem valor={itensIguais} total={totalItens} />
        </strong>
      </td>
      <td>
        <strong className="text-info">
          {isNaN(pendentes) ? "-/-" : pendentes}
          <PorcentagemItem valor={pendentes} total={totalItens} />
        </strong>
      </td>
      <td>
        <strong className="text-success">
          {isNaN(itensAprovados) ? "-/-" : itensAprovados}
          <PorcentagemItem valor={itensAprovados} total={totalItens} />
        </strong>
      </td>
      <td>
        <strong className="text-warning">
          {isNaN(itensReprovados) ? "-/-" : itensReprovados}
          <PorcentagemItem valor={itensReprovados} total={totalItens} />
        </strong>
      </td>
      <td className={`${itensCriticos ? "table-danger" : ""}`}>
        <strong className="text-danger">
          {isNaN(itensCriticos) ? "-/-" : itensCriticos}
          <PorcentagemItem valor={itensCriticos} total={totalItens} />
        </strong>
      </td>
    </>
  );
};

const ProgressoValidacaoPublicacoes = ({ itensIguais, totalItens, itensAprovados, itensReprovados, itensCriticos }) => {
  const progresso = {
    iguais: ((itensIguais / totalItens) * 100).toFixed(2),
    aprovados: ((itensAprovados / totalItens) * 100).toFixed(2),
    reprovados: ((itensReprovados / totalItens) * 100).toFixed(2),
    criticos: ((itensCriticos / totalItens) * 100).toFixed(2),
  };

  return (
    <tr>
      <td className="border-primary border-left-0 border-right-0">Validação:</td>
      <td colSpan={14} className="border-primary border-left-0 border-right-0">
        {itensIguais !== totalItens && (
          <Progress multi style={{ with: "100%", height: "10px" }}>
            <Progress bar color="primary" value={progresso.iguais} />
            <Progress bar color="success" value={progresso.aprovados} />
            <Progress bar color="warning" value={progresso.reprovados} />
            <Progress bar color="danger" value={progresso.criticos} />
          </Progress>
        )}
        {(isNaN(itensIguais) && isNaN(totalItens)) === false && itensIguais === totalItens && (
          <Progress color="primary" value="100" style={{ with: "100%" }}>
            100
          </Progress>
        )}
      </td>
    </tr>
  );
};

const ProgressoSimilaridade = ({ media_de_similaridade }) => {
  const media = media_de_similaridade * 100;
  return (
    <td>
      <Progress value={media}>{media.toFixed(2)}</Progress>
    </td>
  );
};

const CardReportAcoes = ({ reportId, ref_relatorio, released }) => {
  const {
    auth: { isAdmin },
  } = useContext(AuthContext);
  const { deleteReport, changeRelease, removeValidations } = useReportFunctions();

  const callFunc = func => evt => {
    evt.preventDefault();
    func({ reportId, released, ref_relatorio });
  };

  return (
    <td className="text-right acoes">
      <div className="btn-group">
        {isAdmin && (
          <>
            <button onClick={callFunc(removeValidations)} className="btn btn-sm btn-outline-danger px-3">
              <i className="fa fa-check-circle-o" />
            </button>
            <button
              onClick={callFunc(changeRelease)}
              className={`btn btn-sm btn-outline-success px-3 ${released ? "active" : ""}`}
            >
              <i className={`fa ${released ? "fa-eye" : "fa-eye-slash"}`} />
            </button>
            <button onClick={callFunc(deleteReport)} className="btn btn-sm btn-danger px-3">
              <i className="fa fa-times" />
            </button>
          </>
        )}
        <Link className="btn btn-sm btn-primary px-3" to={`/app/report/${ref_relatorio}`}>
          <i className="fa fa-search" />
        </Link>
      </div>
    </td>
  );
};

export default CardReport;
