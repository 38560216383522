import React, { useContext } from "react";
import { Link } from "@reach/router";
import { Button } from "reactstrap";
import { AuthContext } from "../contexts";

const Navbar = () => {
  const {
    auth: { isAuth, username },
    logout,
  } = useContext(AuthContext);

  if (!isAuth) return null;
  return (
    <nav className="navbar navbar-expand navbar-dark bg-dark fixed-top">
      <Link to="/" className="navbar-brand">
        Dashboard
      </Link>
      <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
        <div className="navbar-nav">
          <Link to="/" className="nav-item nav-link">
            Relatórios
          </Link>
          {/* {isAdmin && (
            <Link to="/upload" className="nav-item nav-link">
              Adicionar
            </Link>
          )} */}
        </div>
        <div className="navbar-nav ml-auto">
          <span className="navbar-text">
            {username} (
            <Button color="link" size="sm" onClick={logout}>
              Logout
            </Button>
            )
          </span>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
