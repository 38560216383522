import React from "react";

const Loader = ({ active, message }) =>
  active && (
    <div className="loader">
      <div className="loader__spinner" />
      {message && <p>{message}</p>}
    </div>
  );

Loader.defaultProps = {
  active: false,
};

export default Loader;
