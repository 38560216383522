const regexCnj = /(\d{4,7}-\d{2}\.\d{4}\.\d\.\d{2}\.\d{4})/gm;

const extratorCnj = report => {
  if (report.busca_cnj_realizada) {
    return report;
  }

  return {
    ...report,
    extrator_nao_encontradas: buscaCNJemPublicacoes(report.extrator_nao_encontradas, "texto"),
    sij_nao_encontradas: buscaCNJemPublicacoes(report.sij_nao_encontradas, "textoprocesso"),
    publicacoes_similares: buscaCNJemPublicacoesSimilares(report.publicacoes_similares),
    texto_descartado: destacaCnjTexto(report.texto_descartado, "texto"),
    cnjs_texto_descartado: extraiCNJTexto(report.texto_descartado),
  };
};

const buscaCNJemPublicacoes = (publicacoes, key) =>
  publicacoes.map(p => ({
    ...p,
    cnjs: extraiCNJTexto(p[key]),
    texto: destacaCnjTexto(p[key]),
  }));

const buscaCNJemPublicacoesSimilares = publicacoes =>
  publicacoes.map(p => {
    if (p.diferenca) {
      return {
        ...p,
        cnjs: extraiCNJemPublicacoesDiferentes(p),
      };
    } else if (p.texto) {
      const cnjs_encontrados = extraiCNJTexto(p.texto);
      return {
        ...p,
        cnjs: { sij: cnjs_encontrados, extrator: cnjs_encontrados },
      };
    }
    return p;
  });

const extraiCNJemPublicacoesDiferentes = p => {
  const linhas = p.diferenca.split("\n");
  const cnjs = { extrator: [], sij: [] };

  linhas.forEach(linha => {
    const tipo_linha = linha[0];
    const cnjs_encontrados = extraiCNJTexto(linha);

    switch (tipo_linha) {
      case "+":
        // Caso a linha inicie em '+' quer dizer que é uma linha do extrator
        cnjs.extrator = [].concat(cnjs.extrator, cnjs_encontrados);
        break;
      case "-":
        // Caso a linha inicie em '-' quer dizer que é uma linha do extrator
        cnjs.sij = [].concat(cnjs.sij, cnjs_encontrados);
        break;
      default:
        // Caso contrário, quer dizer que é uma linha comum a ambos
        cnjs.sij = [].concat(cnjs.sij, cnjs_encontrados);
        cnjs.extrator = [].concat(cnjs.extrator, cnjs_encontrados);
        break;
    }
  });

  return cnjs;
};

const destacaCnjTexto = texto => {
  const regexSubstCNJ = '<button class="m-1 btn btn-primary btn-sm py-0 filtrar-cnj">$1</button>';
  return texto.replace(regexCnj, regexSubstCNJ);
};

const extraiCNJTexto = texto => {
  return texto.match(regexCnj) || [];
};

export default extratorCnj;
