import { useState, useEffect } from "react";
import groupBy from "lodash/groupBy";
import { db } from "../utils/firebase";

const useReports = ({ tribunalId, isAdmin }) => {
  const [reports, setReports] = useState(null);
  const [groupedReports, setGroupedReports] = useState(null);
  const [reportsLoaded, setReportsLoaded] = useState(false);

  useEffect(() => {
    if (reports) return;

    let ref;
    if (tribunalId) {
      ref = db
        .ref("indice")
        .orderByChild("extrator")
        .equalTo(tribunalId.toLowerCase());
    } else {
      ref = db.ref("indice");
    }

    ref.on("value", snap => {
      const items = Object.entries(snap.val())
        .map(([key, value]) => ({ reportId: key, ...value }))
        .filter(item => isAdmin || item.released);
      setReports(items);
    });
  }, [reports, tribunalId, isAdmin]);

  useEffect(() => {
    if (!reports || Object.keys(reports).length === 0) return;

    const groupedByExtrator = groupBy(reports, "extrator");

    const grouped = Object.entries(groupedByExtrator).map(([tribunal, relatorios]) => {
      const groupedByCaderno = groupBy(relatorios, "nome_caderno");
      return {
        nome: tribunal,
        cadernos: Object.entries(groupedByCaderno).map(([nome, relatorios]) => ({ nome, relatorios })),
      };
    });

    setGroupedReports(grouped);
    setReportsLoaded(true);
  }, [reports]);

  return { groupedReports, reportsLoaded };
};

export default useReports;
