import React from "react";
import Constants from "../utils/constants";

const HEADERS = [
  { name: "Data" },
  { name: "Publicações encontradas", children: [{ name: Constants.EXTRACAO_1 }, { name: Constants.EXTRACAO_2 }, { name: "Diferença" }] },
  { name: "Publicações não encontradas", children: [{ name:Constants.EXTRACAO_1 }, { name: Constants.EXTRACAO_2 }] },
  { name: "Sem vara", children: [{ name:Constants.EXTRACAO_1 }, { name: Constants.EXTRACAO_2 }] },
  {
    name: "Status validação",
    children: [
      { name: "Iguais" },
      { name: "Pendentes" },
      { name: "Aprovadas" },
      { name: "Reprovadas" },
      { name: "Críticas" },
    ],
  },
  { name: "Similaridade entre publicações" },
  { name: "Ações" },
];

const CardReportHeader = () => (
  <thead>
    <tr>
      {HEADERS.map((item, idx) => (
        <th key={idx} rowSpan={item.children ? 1 : 2} colSpan={item.children ? item.children.length : 1}>
          {item.name}
        </th>
      ))}
    </tr>
    <tr>
      {HEADERS.filter(item => item.children)
        .map(item => item.children)
        .flat()
        .map((item, idx) => (
          <th key={idx}>{item.name}</th>
        ))}
    </tr>
  </thead>
);

export default CardReportHeader;
