import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";

var config = {
  apiKey: "AIzaSyDitPHvKwC3m77W4YGjcfyWgs9wkTmVpRo",
  authDomain: "comparador-jf.firebaseapp.com",
  databaseURL: "https://comparador-jf.firebaseio.com",
  projectId: "comparador-jf",
  storageBucket: "comparador-jf.appspot.com",
  messagingSenderId: "998716604743",
};

firebase.initializeApp(config);

export const auth = firebase.auth();
export const db = firebase.database();
export const storage = firebase.storage();
