import React, { useState, useEffect, useMemo } from "react";
import { Alert } from "reactstrap";
import { ReportViewPaginador } from "./";
import { ReportItem } from "./";
import { db } from "../utils/firebase";
import sortBy from "lodash.sortby";

const filterPublicacoes = (publicacoes, filter, aprovacoes) => {
  if (filter === undefined) {
    return [];
  }

  return sortBy(publicacoes, "taxa_similaridade").filter(item => {
    const texto = (item.diferenca || item.texto).toLowerCase();
    const aprovacao = aprovacoes[`${item.codigo}__${item.hash}`];

    if (!filter.exibirAprovados && aprovacao === 0) {
      return false;
    }
    if (!filter.exibirReprovados && aprovacao === 1) {
      return false;
    }
    if (!filter.exibirCriticos && aprovacao === 2) {
      return false;
    }
    if (item.taxa_similaridade * 100 > filter.similaridade) {
      return false;
    }
    if (!filter.exibirIguais && item.taxa_similaridade === 1) {
      return false;
    }
    if (filter.term && texto.indexOf(filter.term.toLowerCase()) === -1) {
      return false;
    }
    return item;
  });
};

const paginaPublicacoes = (publicacoes, { itemsPerPage, paginaAtual }) => {
  let indexAtual = (paginaAtual || 0) * itemsPerPage;
  return publicacoes.slice(indexAtual, indexAtual + itemsPerPage);
};

const ReportViewPublicacoes = ({ filter, publicacoes, aprovacoes, reportId, setTerm }) => {
  const [pagination, setPagination] = useState({
    indexAtual: 0,
    paginaAtual: 0,
    itemsPerPage: 25,
  });

  const refStatus = db.ref(`aprovacoes/${reportId}`);

  let filteredReportItems = useMemo(() => filterPublicacoes(publicacoes, filter, aprovacoes), [
    publicacoes,
    filter,
    aprovacoes,
  ]);

  const paginatedReportItems = useMemo(() => paginaPublicacoes(filteredReportItems, pagination), [
    filteredReportItems,
    pagination,
  ]);

  useEffect(() => {
    window.scrollTo(0, 250);
  }, [pagination]);

  const handleChangeStatus = (key, statusVal) => {
    if (statusVal === undefined) {
      refStatus.child(key).remove();
    } else {
      refStatus.child(key).set(statusVal);
    }
  };

  const handleChangeAllStatus = value => {
    paginatedReportItems.forEach(item => handleChangeStatus(`${item.codigo}__${item.hash}`, value));
    window.scrollTo(0, 250);
  };

  return (
    <>
      {reportId && (
        <div className="row bg-light p-2 my-2">
          <ToggleStatusAllVisible onChangeAllStatus={handleChangeAllStatus} />
          <ReportViewPaginador
            setPagination={setPagination}
            pagination={pagination}
            itensCount={filteredReportItems.length}
          />
        </div>
      )}

      <div className="row bg-light p-2 my-2">
        {paginatedReportItems.length === 0 && (
          <Alert color="info" className="my-2 w-100">
            Nenhuma publicação encontrada
          </Alert>
        )}

        {paginatedReportItems.length > 0 &&
          paginatedReportItems.map((publicacao, idx) => (
            <ReportItem
              key={publicacao.codigo || idx}
              filter={filter}
              onChangeStatus={handleChangeStatus}
              reportId={reportId}
              status={aprovacoes[`${publicacao.codigo}__${publicacao.hash}`]}
              publicacao={publicacao}
              setTerm={setTerm}
            />
          ))}
      </div>
      {reportId && (
        <div className="row bg-light p-2 my-2">
          <ToggleStatusAllVisible onChangeAllStatus={handleChangeAllStatus} />
          <ReportViewPaginador
            setPagination={setPagination}
            pagination={pagination}
            itensCount={filteredReportItems.length}
          />
        </div>
      )}
    </>
  );
};

const ToggleStatusAllVisible = ({ onChangeAllStatus }) => {
  const items = [
    { text: "Aprovar", value: 0, color: "success" },
    { text: "Reprovar", value: 1, color: "warning" },
    { text: "Reprovar Crítico", value: 2, color: "danger" },
  ];
  return (
    <div className="col">
      {items.map((item, i) => (
        <button key={i} className={`btn btn-${item.color} btn-sm mr-1`} onClick={() => onChangeAllStatus(item.value)}>
          {item.text}
        </button>
      ))}
      <span className="ml-1">Todos os visiveis</span>
    </div>
  );
};

export default ReportViewPublicacoes;
