import React, { useContext } from "react";
import { AuthContext } from "../contexts";
import { useReportFunctions } from "../hooks";

const ReportViewHeader = ({ reportId, nome_caderno, data_publicacao, released = false }) => {
  const {
    auth: { isAdmin },
  } = useContext(AuthContext);
  const { changeRelease, removeValidations } = useReportFunctions();

  const callFunc = func => evt => {
    evt.preventDefault();
    func({ reportId, released });
  };

  return (
    <h2 className="border-bottom pb-2 mb-3">
      {nome_caderno} - <small>{data_publicacao}</small>
      {isAdmin && (
        <div className="pull-right">
          <ReleaseReport released={released} onChangeRelease={callFunc(changeRelease)} />
          <RemoveValidations onRemoveValidations={callFunc(removeValidations)} />
        </div>
      )}
    </h2>
  );
};

const ReleaseReport = ({ released, onChangeRelease }) => (
  <button
    type="button"
    className={`btn btn-sm ml-3 btn-outline-success ${released ? "active" : ""}`}
    onClick={e => onChangeRelease(e)}
  >
    <i className={`fa mr-2 ${released ? "fa-eye" : "fa-eye-slash"}`} />
    {released ? "Visível para revisão" : "Oculto para revisão"}
  </button>
);

const RemoveValidations = ({ onRemoveValidations }) => (
  <button type="button" className="btn btn-sm btn-danger ml-3" onClick={onRemoveValidations}>
    <i className="fa fa-times mr-2" />
    Remover validações
  </button>
);

export default ReportViewHeader;
